import React, { useEffect, useRef, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { Box } from "@mui/material";
import { useFeedback } from "../FeedbackContext";
import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import { themeJson } from "../../../Common/theme";
import * as SurveyCore from "survey-core";
import { nouislider } from "surveyjs-widgets";
import "../../../Common/nouislider.css";
import "./filter.css";

nouislider(SurveyCore);

/**
 * SurveyElement - A versatile component for displaying surveys
 * 
 * @param {Object} json - Survey JSON structure
 * @param {string|number} questionId - ID of the question for filtering (optional)
 * @param {string} primaryBackcolor - Theme color (optional)
 * @param {boolean} preview - Whether in preview mode (optional)
 * @param {string} className - CSS class (optional)
 * @param {function} onValueChanged - Custom value change handler (optional)
 * @returns {JSX.Element}
 */
function SurveyElement({ 
  json, 
  questionId = null,
  primaryBackcolor = "var(--sjs-primary-backcolor)",
  preview = true,
  className = "questionPreview",
  onValueChanged = null
}) {
  const { updateFilters, filterState } = useFeedback();
  const [surveyModel, setSurveyModel] = useState(null);
  const surveyRef = useRef(null);
  const valueChangeHandlerRef = useRef(null);
  const questionNameRef = useRef(null);
  const jsonRef = useRef(json);

  // Only create a new survey model when json or questionId changes
  useEffect(() => {
    if (!json || !json.pages || !json.pages[0]?.elements?.[0]) return;
    
    try {
      // Make a deep copy to avoid modifying the original
      const jsonCopy = JSON.parse(JSON.stringify(json));
      const processedJson = processNames(jsonCopy);

      // Get question name and store it in ref
      console.log("processedJson", processedJson);
      const questionName = "test";
      processedJson.pages[0].elements[0].name = questionName;
      questionNameRef.current = questionName;
      
      // Create new survey instance
      const newSurvey = new Model(processedJson);
      
      // Apply theme and settings
      const theme = { ...themeJson };
      theme.cssVariables["--sjs-primary-backcolor"] = primaryBackcolor;
      newSurvey.applyTheme(theme);
      newSurvey.showCompleteButton = !preview;

      // Check localStorage for stored values
      let storedValue = undefined;
      try {
        const storedState = localStorage.getItem('feedbackContextState');
        if (storedState) {
          const parsedState = JSON.parse(storedState);
          storedValue = parsedState?.filters?.questionValue;
        console.log("storedValue", storedValue);
        console.log("questionName", questionName);
          // If we have a saved value, set it as the default in the JSON
          if (storedValue !== undefined && storedValue !== null) {
            newSurvey.setValue(questionName, storedValue);
          }
        }
      } catch (e) {
        console.error("Error reading from localStorage:", e);
      }
      
      // Store the new survey model
      setSurveyModel(newSurvey);
      surveyRef.current = newSurvey;
      jsonRef.current = json;
    } catch (error) {
      console.error("Error setting up survey:", error);
    }
    console.log(json)
  }, [json]);

  // Handle value changes and initial value separately
  useEffect(() => {
    const survey = surveyRef.current;
    const questionName = questionNameRef.current;

    if (!survey || !questionName) return;

    // Remove any existing handler to prevent duplicates
    if (valueChangeHandlerRef.current) {
      survey.onValueChanged.remove(valueChangeHandlerRef.current);
    }

    // Create a new handler
    const valueChangeHandler = (sender, options) => {
      if (questionId) {
        updateFilters({
          ...filterState,
          filters: {
            ...filterState.filters,
            Question: [questionId],
            questionValue: options.value,
          },
        });
      }
      
      if (onValueChanged) {
        onValueChanged(options.value);
      }
    };

    survey.onValueChanged.add(valueChangeHandler);
    valueChangeHandlerRef.current = valueChangeHandler;

    // Apply initial value if available
    if (questionId && filterState?.filters?.questionValue !== undefined) {
      // Set value without triggering the value changed event
      survey.data = {
        ...survey.data,
        [questionName]: filterState.filters.questionValue
      };
    }
  }, [questionId, filterState?.filters?.Question, updateFilters, onValueChanged, jsonRef.current]);

  if (!surveyModel) return null;

  const content = <Survey model={surveyModel} className={className} />;
  
  return preview ? (
    <Box sx={{ marginY: 2 }}>
      {content}
    </Box>
  ) : content;
}

// Process question names to ensure uniqueness
function processNames(json) {
  if (!json || !json.pages) return json;
  
  const nameCount = {};
  
  json.pages.forEach(page => {
    if (!page.elements) return;
    
    page.elements.forEach(element => {
      if(typeof element.choices === 'string')
      {
        element.choices=JSON.parse(element.choices);
      }
      const name = element.name;
      if (nameCount[name]) {
        element.name = `${name}_${String.fromCharCode(96 + nameCount[name])}`;
        nameCount[name]++;
      } else {
        nameCount[name] = 1;
      }
    });
  });
  
  return json;
}

export default SurveyElement;
