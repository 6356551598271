import React, { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import { FaComments } from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AIReport from "../Evaluation/AIReport";
import { formatDate } from "../Dashboard/dashboardEvaluationFunctions";
import ContentPasteTwoToneIcon from "@mui/icons-material/ContentPasteTwoTone";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import UserAvatar from "../../Users/UserAvatar";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import noDataImage from "../../../Assets/nodata.svg";

export function Comments({ feedbackData, width = "100%" }) {
  const [report, setReport] = useState("");
  const [reportVisibility, setReportVisibility] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // State to keep track of how many comments are currently shown
  const [itemsToShow, setItemsToShow] = useState(10);

  const navigate = useNavigate();
  let allComments = feedbackData.filter((comment) => comment.Typ === "Text");

  const searchedComments = allComments.filter((formData) => {
    const answerText = formData.Value.slice(1, -1).toLowerCase();
    return answerText.includes(searchTerm.toLowerCase());
  });

  /**
   * Reset itemsToShow to 10 whenever we change the search term
   * or switch from AI report to comments, so we always start
   * from the first 10 visible items.
   */
  useEffect(() => {
    setItemsToShow(10);
  }, [searchTerm, reportVisibility]);

  /**
   * Handle scrolling inside the Paper. If we reach near the bottom,
   * load 10 more items (unless we’ve already shown them all).
   */
  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      // Check if we're near the bottom (e.g., within 50px)
      if (scrollHeight - scrollTop - clientHeight < 50) {
        setItemsToShow((prev) => {
          // Avoid showing more than exist
          if (prev < searchedComments.length) {
            return prev + 10;
          }
          return prev;
        });
      }
    },
    [searchedComments]
  );

  return (
    <Fade in={true}>
      <Paper
        className="paper"
        onScroll={handleScroll}
        style={{
          position: "relative",
          width: width,
          display: "flex",
          flexDirection: "column",
          maxHeight: "550px",
          overflowY: "auto",
          padding: "1rem",
        }}
      >
        {/* Header + Search + Navigation buttons */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <header>Kommentare</header>
          <Stack direction="row" spacing={2}>
            <Tooltip title="KI-Report">
              <IconButton
                size="small"
                onClick={() => setReportVisibility(true)}
              >
                <AutoAwesomeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Kommentare">
              <IconButton
                size="small"
                onClick={() => setReportVisibility(false)}
              >
                <FaComments />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        {/* Search input field */}
        {!reportVisibility && (
          <Stack direction="row" justifyContent="flex-start" sx={{ mb: 2 }}>
            <TextField
              style={{ width: "450px" }}
              variant="standard"
              label="Kommentare durchsuchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton sx={{ mr: 0 /* optional spacing */ }}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
        )}

        {reportVisibility ? (
          searchedComments.slice(0, itemsToShow).length > 0 ? (
            <AIReport
              report={report}
              setReport={setReport}
              comments={allComments
                .map(
                  (formData) =>
                    "Trainer: " +
                    formData.TrainerName +
                    ",Kurs: " +
                    formData.CourseName +
                    ",Datum: " +
                    formatDate(formData.Date) +
                    ",Frage: " +
                    formData.Fragenbeschreibung +
                    ",Kommentar: " +
                    formData.Value.slice(1, -1)
                )
                .join(" ")}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                src={noDataImage}
                alt="no data"
                style={{ width: "80%", maxWidth: "400px" }}
              />{" "}
            </div>
          )
        ) : (
          <Stack direction="column" spacing={2}>
            {/* 
              Only slice the number of items to show (itemsToShow).
              This way we only render the first `itemsToShow` comments.
            */}
            {searchedComments.slice(0, itemsToShow).length > 0 ? (
              searchedComments.slice(0, itemsToShow).map((formData, index) => (
                <Card
                  key={index}
                  style={{ backgroundColor: "rgb(244, 250, 251)" }}
                >
                  <CardContent style={{ position: "relative" }}>
                    <IconButton
                      onClick={() => {
                        navigate("/evaluation/surveys/" + formData.id);
                      }}
                      edge="end"
                      aria-label="fragebogenansicht"
                      style={{
                        position: "absolute",
                        top: "7px",
                        right: "17px",
                      }}
                    >
                      <ContentPasteTwoToneIcon
                        fontSize="medium"
                        color="primary"
                      />
                    </IconButton>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Trainer: {formData.TrainerName}
                      </Typography>
                      <UserAvatar
                        name={formData.TrainerName}
                        src={formData.Avatarfile}
                        marginLeft={"10px"}
                        marginRight={"10px"}
                        width={"25px"}
                        height={"25px"}
                      />
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                      {formData.CourseName}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      style={{ marginBottom: "100px" }}
                    >
                      {formatDate(formData.Date)}
                    </Typography>

                    <div style={{ margin: "10px 0px 0px" }}>
                      <Typography variant="subtitle2">
                        Frage: {formData.Fragenbeschreibung}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        Antwort: {formData.Value.slice(1, -1)}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                <img
                  src={noDataImage}
                  alt="no data"
                  style={{ width: "80%", maxWidth: "400px" }}
                />{" "}
              </div>
            )}
          </Stack>
        )}
      </Paper>
    </Fade>
  );
}
