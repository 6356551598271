import React, { useEffect, useState } from "react";
import { Comments } from "../Comments/Comments";
import "../Evaluation/evaluation.css";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import ReactApexChart from "react-apexcharts";
import { DonutDiagram } from "../../Diagrams/DonutDiagram";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import surveysImage from "../../../Assets/surveys.png";
import teacherImage from "../../../Assets/teacher.png";
import categoryImage from "../../../Assets/classification.png";
import graduationHatImage from "../../../Assets/graduation-hat.png";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LocationOn from "@mui/icons-material/LocationOn";
import DonutSmall from "@mui/icons-material/DonutSmall";
import { FaUserGraduate, FaChartColumn, FaChartLine } from "react-icons/fa6";
import {
  berechneDurchschnittBewertung,
  countNumberOfCourses,
  countNumberOfFeedback,
  countNumberOfTrainers,
  createObjectForCoursePieChart,
  createObjectForTimelineChart,
  createObjectForTrainerPieChart,
  createSeriesForCoursePieChart,
  createSeriesForEvaluationPieChart,
  createSeriesForRadialChart,
  createSeriesForTrainerPieChart,
  getNumberForTimelineChart,
} from "./dashboardEvaluationFunctions";
import Empty from "../../../Common/Empty";
import TrainerDiagram from "../Evaluation/Diagrams/TrainerDiagram";
import CoursesDiagram from "../Evaluation/Diagrams/CoursesDiagram";
import BewertungTimelineDiagram from "../Evaluation/Diagrams/BewertungTimelineDiagram";
import { RadialDiagram } from "../../Diagrams/RadialDiagram";
import "./dashboard.css";
import FeedbackTimeline from "./FeedbackTimeline";
import { useNavigate } from "react-router-dom";
import BewertungBarDiagram from "../Evaluation/Diagrams/BewertungBarDiagram";
import CategoryIcon from "@mui/icons-material/Category";
import LocationChart from "./LocationChart";
import CategoryChart from "./CategoryChart";
import DisciplineChart from "./DisciplineChart";

export default function DashboardEvaluationModule(props) {
  const [seriesForEvaluationPieChart, setSeriesForEvaluationPieChart] =
    useState([]);
  const [seriesForTrainerPieChart, setSeriesForTrainerPieChart] = useState([]);
  const [labelsForTrainerPieChart, setLabelsForTrainerPieChart] = useState([]);
  const [seriesForCoursePieChart, setSeriesForCoursePieChart] = useState([]);
  const [labelsForCoursePieChart, setLabelsForCoursePieChart] = useState([]);
  const [seriesForTimelineChart, setSeriesForTimelineChart] = useState([]);
  const [numberForTimelineChart, setNumberForTimelineChart] = useState([]);
  const [average, setAverage] = useState(undefined);
  const [numberOfFeedback, setNumberOfFeedback] = useState(0);
  const [numberOfTrainers, setNumberOfTrainers] = useState(0);
  const [numberOfCourses, setNumberOfCourses] = useState(0);
  const navigate = useNavigate();
  const [diagramState, setDiagramState] = useState("Trainer");
  const [diagramStateSecond, setDiagramStateSecond] = useState("Evaluation");
  const [disciplineOptions, setDisciplineOptions] = useState(RadialDiagram);
  const [locationOptions, setLocationOptions] = useState(RadialDiagram);
  const [lineView, setLineView] = useState(true);

  useEffect(() => {
    if (props.feedbackData.length !== 0) {
      setAverage(berechneDurchschnittBewertung(props.feedbackData));
      setNumberOfFeedback(countNumberOfFeedback(props.feedbackData));
      setNumberOfTrainers(countNumberOfTrainers(props.feedbackData));
      setNumberOfCourses(countNumberOfCourses(props.feedbackData));
      setSeriesForEvaluationPieChart(
        createSeriesForEvaluationPieChart(props.feedbackData)
      );


      let trainerObject = createObjectForTrainerPieChart(props.feedbackData);
      trainerObject = createSeriesForTrainerPieChart(trainerObject);
      setLabelsForTrainerPieChart(trainerObject.labels);
      setSeriesForTrainerPieChart(trainerObject.series);

      let coursesObject = createObjectForCoursePieChart(props.feedbackData);
      coursesObject = createSeriesForCoursePieChart(coursesObject);
      setLabelsForCoursePieChart(coursesObject.labels);
      setSeriesForCoursePieChart(coursesObject.series);

      const timelineObject = createObjectForTimelineChart(props.feedbackData);
      setSeriesForTimelineChart(timelineObject);

      const numberForTimelineArray = getNumberForTimelineChart(
        props.feedbackData
      );
      setNumberForTimelineChart(numberForTimelineArray);
    }
  }, [props.feedbackData]);

  let evaluationLink = "../evaluation/surveys";

  function handleLinkClick(page) {
    navigate("../" + page);
  }

  function navigateToSurveyOverview() {
    navigate(evaluationLink);
  }

  return (
    <>
      {props.feedbackData.length !== 0 ? (
        <>
          <Grid item xs={12} lg={8.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Fade in={true}>
                  <Paper
                    className="paper link"
                    onClick={() => navigateToSurveyOverview()}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Stack direction="column">
                        <header>Beurteilte Fragebögen</header>
                        <h2>{numberOfFeedback}</h2>
                      </Stack>
                      <img
                        className="pageIconDashboard"
                        src={surveysImage}
                        alt="checkmark"
                      />
                    </Stack>
                  </Paper>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Fade in={true}>
                  <Paper
                    className="paper link"
                    onClick={() => handleLinkClick("users")}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Stack direction="column">
                        <header>Beurteilte Trainer/-in</header>
                        <h2>{numberOfTrainers}</h2>
                      </Stack>
                      <img
                        className="pageIconDashboard"
                        src={teacherImage}
                        alt="checkmark"
                      />
                    </Stack>
                  </Paper>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Fade in={true}>
                  <Paper
                    className="paper link"
                    onClick={() => handleLinkClick("courses")}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Stack direction="column">
                        <header>Beurteilte Kurse</header>
                        <h2>{numberOfCourses}</h2>
                      </Stack>
                      <img
                        className="pageIconDashboard"
                        src={graduationHatImage}
                        alt="checkmark"
                      />
                    </Stack>
                  </Paper>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <Paper className="paper" style={{ height: "350px" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{ width: "100%", paddingLeft: "10px" }}
                  >
                    <header>
                      {diagramState === "Evaluation" && "Bewertung insgesamt"}{" "}
                      {diagramState === "Trainer" &&
                        (props.target === "Kursteilnehmer"
                          ? "Welche Trainer/-innen wurden bewertet?"
                          : "Welche Benutzer haben bewertet?")}
                      {diagramState === "Courses" &&
                        "Welche Kurse wurden bewertet?"}
                    </header>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      spacing={0.3}
                      style={{ width: "100px" }}
                    >
                      <Tooltip title="Trainer">
                        <IconButton
                          className="navigator"
                          size="small"
                          color={
                            diagramState === "Trainer" ? "primary" : "default"
                          }
                          onClick={() => setDiagramState("Trainer")}
                        >
                          <FaUserGraduate fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Kurse">
                        <IconButton
                          className="navigator"
                          size="small"
                          onClick={() => setDiagramState("Courses")}
                        >
                          <SchoolIcon
                            fontSize="small"
                            color={
                              diagramState === "Courses" ? "primary" : "default"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                  {diagramState === "Trainer" && (
                    <TrainerDiagram
                      labelsForTrainerPieChart={labelsForTrainerPieChart}
                      seriesForTrainerPieChart={seriesForTrainerPieChart}
                    />
                  )}
                  {diagramState === "Courses" && (
                    <CoursesDiagram
                      labelsForCoursePieChart={labelsForCoursePieChart}
                      seriesForCoursePieChart={seriesForCoursePieChart}
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6.5}>
                <Paper className="paper" style={{ height: "350px" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{ width: "100%", paddingLeft: "10px" }}
                  >
                    <header>
                      {diagramStateSecond === "Evaluation" &&
                        "Bewertung gesamt"}{" "}
                      {diagramStateSecond === "Categories" &&
                        "Bewertung nach Kategorien"}{" "}
                      {diagramStateSecond === "Locations" &&
                        "Bewertung nach Standorten"}
                      {diagramStateSecond === "Disciplines" &&
                        "Bewertung nach Fachbereichen"}
                    </header>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      spacing={0.3}
                      style={{ width: "100px" }}
                    >
                      <Tooltip title="Bewertung insgesamt">
                        <IconButton
                          className="navigator"
                          size="small"
                          onClick={() => setDiagramStateSecond("Evaluation")}
                        >
                          <DonutSmall
                            fontSize="small"
                            color={
                              diagramStateSecond === "Evaluation"
                                ? "primary"
                                : "default"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Kategorien">
                        <IconButton
                          className="navigator"
                          size="small"
                          onClick={() => setDiagramStateSecond("Categories")}
                        >
                          <CategoryIcon
                            fontSize="small"
                            color={
                              diagramStateSecond === "Categories"
                                ? "primary"
                                : "default"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                      {props.feedbackData[0].isActive === 1 && (
                        <Tooltip title="Standorte">
                          <IconButton
                            className="navigator"
                            size="small"
                            onClick={() => setDiagramStateSecond("Locations")}
                          >
                            <LocationOn
                              fontSize="small"
                              color={
                                diagramStateSecond === "Locations"
                                  ? "primary"
                                  : "default"
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Disciplines">
                        <IconButton
                          className="navigator"
                          size="small"
                          onClick={() => setDiagramStateSecond("Disciplines")}
                        >
                          <LocalLibraryIcon
                            fontSize="small"
                            color={
                              diagramStateSecond === "Disciplines"
                                ? "primary"
                                : "default"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                  {diagramStateSecond === "Evaluation" && (
                    <ReactApexChart
                      options={DonutDiagram.options}
                      series={seriesForEvaluationPieChart}
                      height={"100%"}
                      type="donut"
                    />
                  )}
                  {diagramStateSecond === "Categories" && (
                    <CategoryChart feedbackData={props.feedbackData} />
                  )}
                  {diagramStateSecond === "Locations" && (
                    <LocationChart feedbackData={props.feedbackData} />
                  )}
                  {diagramStateSecond === "Disciplines" && (
                    <DisciplineChart feedbackData={props.feedbackData} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3.5}>
              <FeedbackTimeline
                data={props.feedbackData}
                target={props.target}
              />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Comments feedbackData={props.feedbackData} />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Fade in={true}>
              <Paper
                className="paper"
                style={{ paddingBottom: "0px", position: "relative" }}
              >
                {seriesForTimelineChart.length > 1 && (
                  <Tooltip
                    title={lineView ? "Balkendiagramm" : "Liniendiagramm"}
                  >
                    <IconButton
                      className="navigator"
                      size="small"
                      style={{
                        position: "absolute",
                        right: "60px",
                        top: "12.5px",
                      }}
                      onClick={() => setLineView(!lineView)}
                    >
                      {lineView ? <FaChartColumn /> : <FaChartLine />}
                    </IconButton>
                  </Tooltip>
                )}
                {seriesForTimelineChart.length > 1 && lineView ? (
                  <BewertungTimelineDiagram
                    seriesForTimelineChart={seriesForTimelineChart}
                    numberForTimelineChart={numberForTimelineChart}
                    type={"area"}
                    average={average}
                    max={5}
                  />
                ) : (
                  <BewertungBarDiagram
                    seriesForTimelineChart={seriesForTimelineChart}
                    numberForTimelineChart={numberForTimelineChart}
                    type={"bar"}
                    average={average}
                    max={5}
                  />
                )}
              </Paper>
            </Fade>
          </Grid>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}
