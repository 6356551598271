import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FaFilter } from "react-icons/fa6";
import DateRange from "../DateRange";
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { updateState } from "../../Surveys/surveysFunctions";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { UserContext } from "../../Users/UserContext";
import FilterChips from "./FilterChips";
import { getOldestDate } from "../Evaluation/evaluationFunctions";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import TargetButton from "../Dashboard/TargetButton";
import FilterSelect from "./FilterSelect";
import { useFeedback } from "../FeedbackContext";
import { FaFileExport } from "react-icons/fa6";
import { Box, Tooltip } from "@mui/material";
import { useLocalStorage } from "../../../Common/useLocalStorage";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { sendGetRequest } from "../../../Common/sendGetRequest";
import { generateJson } from "../../Questions/questionsFunctions";
import { SurveyJsQuestion } from "../../../SurveyJSJsons/SurveyJsQuestion";
import SurveyElement from "./SurveyElement";

// Update export function with UTF-8 BOM and proper encoding
const exportToCSV = (feedbackData) => {
  const headers = [
    "Date",
    "Value",
    "Target",
    "TrainerName",
    "CourseName",
    "Coursenumber",
    "Fragenbeschreibung",
    "Categoryname",
    "SurveyName",
    "LocationName",
    "DisciplineName",
  ];

  // Add UTF-8 BOM
  const BOM = "\uFEFF";

  const csvContent =
    BOM +
    [
      headers.join(";"),
      ...feedbackData.map((item) =>
        headers
          .map((header) => {
            const value = item[header] || "";
            // Properly escape and format cell content
            return value
              .toString()
              .replace(/"/g, '""') // Escape quotes
              .replace(/;/g, ",") // Replace semicolons with commas
              .replace(/\n/g, " "); // Replace newlines with spaces
          })
          .join(";")
      ),
    ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute(
    "download",
    `feedback_export_${new Date().toISOString().split("T")[0]}.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    width: { xs: "90vw", sm: "700px" }, // Adjusted width for 2 columns
    maxWidth: "95vw",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {},
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      padding: theme.spacing(0, 2),
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
  },
}));

export default function Filter({ target, setTarget, component }) {
  const { updateFilters, filterState, allFeedbackData, feedbackData } =
    useFeedback();
    
  const [json, setJson] = React.useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { currentUserData } = useContext(UserContext);
  const [userAvatar, setUserAvatar] = useState([]);
  const [startDate, setStartDate] = useState(
    allFeedbackData.length > 0
      ? getOldestDate(allFeedbackData)
      : new Date(2020, 2, 27)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [trainerOptions, setTrainerOptions] = useState([]);
  const [allUserOptions, setAllUserOptions] = useState([]);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [selectedTrainers, setSelectedTrainers] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [isLocationActive, setIsLocationActive] = useState(0);
  const [courseNumberOptions, setCourseNumberOptions] = useState([]);
  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [filter, setFilter] = useLocalStorage("feedbackFilter", {
    Category: queryParams.get("category")
      ? queryParams.get("category").split(",").map(Number)
      : [],
    Survey: queryParams.get("survey")
      ? queryParams.get("survey").split(",").map(Number)
      : [],
    Trainer: queryParams.get("trainer")
      ? queryParams.get("trainer").split(",").map(Number)
      : [],
    Course: queryParams.get("course")
      ? queryParams.get("course").split(",").map(Number)
      : [],
    Location: queryParams.get("location")
      ? queryParams.get("location").split(",").map(Number)
      : [],
    Coursenumber: queryParams.get("coursenumber")
      ? queryParams.get("coursenumber").split(",").map(Number)
      : [],
    Discipline: queryParams.get("discipline")
      ? queryParams.get("discipline").split(",").map(Number)
      : [],
    Question: [], // Add Question to filter state
  });

  const [filterName, setFilterName] = useLocalStorage("feedbackFilterNames", {
    Category: "",
    Survey: "",
    Trainer: "",
    Course: "",
    Location: "",
    Coursenumber: "",
    Discipline: "",
    Question: "", // Add Question to filterName state
  });

  // Extract unique options from allFeedbackData
  useEffect(() => {
    const trainersSet = new Set();
    const surveysSet = new Set();
    const coursesSet = new Set();
    const categoriesSet = new Set();
    const locationsSet = new Set();
    const courseNumbersSet = new Set(); // New set for course numbers
    const disciplinesSet = new Set();
    const questionsSet = new Set();
    const usersMap = new Map(); // To map Trainer ID to user details

    let feedbackData = allFeedbackData.filter(
      (feedback) => feedback.Target === target
    );

    feedbackData.forEach((feedback) => {
      // Trainers
      if (feedback.Trainer) {
        trainersSet.add(feedback.Trainer);
      }

      // Surveys
      if (feedback.Survey && feedback.SurveyName) {
        surveysSet.add(
          JSON.stringify({
            id: feedback.Survey,
            ID: feedback.Survey,
            Survey: feedback.SurveyName,
            Target: feedback.Target,
          })
        );
      }

      // Courses
      if (feedback.Course && feedback.CourseName) {
        coursesSet.add(
          JSON.stringify({ id: feedback.Course, Kurs: feedback.CourseName })
        );
      }

      // Questions
      if (feedback.QuestionID && feedback.Fragenbeschreibung) {
        questionsSet.add(
          JSON.stringify({
            id: feedback.QuestionID,
            Fragenbeschreibung: feedback.Fragenbeschreibung,
          })
        );
      }

      // Categories
      if (feedback.Category && feedback.Categoryname) {
        categoriesSet.add(
          JSON.stringify({
            id: feedback.Category,
            Category: feedback.Categoryname,
          })
        );
      }

      // Locations
      if (feedback.Location && feedback.LocationName) {
        locationsSet.add(
          JSON.stringify({
            id: feedback.Location,
            LocationName: feedback.LocationName,
            isActive: feedback.isActive,
          })
        );
      }

      // Course Numbers
      if (feedback.Coursenumber) {
        courseNumbersSet.add(feedback.Coursenumber);
      }

      // Disciplines
      if (feedback.Discipline && feedback.DisciplineName) {
        disciplinesSet.add(
          JSON.stringify({
            id: feedback.Discipline,
            Discipline: feedback.DisciplineName,
          })
        );
      }

      // Users Map (Assuming TrainerName and Avatarfile are available)
      if (feedback.Trainer && feedback.TrainerName) {
        usersMap.set(feedback.Trainer, {
          id: feedback.Trainer,
          TrainerName: feedback.TrainerName,
          Avatarfile: feedback.Avatarfile ? feedback.Avatarfile : "",
        });
      }
    });

    // Convert sets to arrays
    const trainersArray = Array.from(trainersSet).map((trainerId) => {
      const user = usersMap.get(trainerId) || {
        id: trainerId,
        TrainerName: "Unknown",
        Avatarfile: "",
      };
      return user;
    });

    const surveysArray = Array.from(surveysSet).map((item) => JSON.parse(item));
    const coursesArray = Array.from(coursesSet).map((item) => JSON.parse(item));
    const questionsArray = Array.from(questionsSet).map((item) =>
      JSON.parse(item)
    );
    const categoriesArray = Array.from(categoriesSet).map((item) =>
      JSON.parse(item)
    );
    const locationsArray = Array.from(locationsSet).map((item) =>
      JSON.parse(item)
    );
    const courseNumbersArray = Array.from(courseNumbersSet).map(
      (courseNumber) => ({
        id: courseNumber,
        Coursenumber: courseNumber,
      })
    );
    const disciplinesArray = Array.from(disciplinesSet).map((item) =>
      JSON.parse(item)
    );

    setIsLocationActive(
      locationsArray.length > 0 ? locationsArray[0].isActive : false
    );

    setTrainerOptions(trainersArray);
    setSurveyOptions(surveysArray);
    setCourseOptions(coursesArray);
    setQuestionOptions(questionsArray);
    setCategoryOptions(categoriesArray);
    setLocationOptions(locationsArray);
    setCourseNumberOptions(courseNumbersArray); // Set course number options
    setDisciplineOptions(disciplinesArray);
    setAllUserOptions(trainersArray); // Assuming allUserOptions are trainers

    // Initialize filter names
    let filterNameTemp = {
      Category: "",
      Survey: "",
      Trainer: "",
      Course: "",
      Location: "",
      Coursenumber: "", // New filter name for course number
      Discipline: "",
      Question: "", // Add Question to filterName state
    };

    // Set Trainer Name and Avatar
    if (filter.Trainer.length > 0) {
      const currentTrainers = trainersArray.filter((trainer) =>
        filter.Trainer.includes(trainer.id)
      );
      filterNameTemp.Trainer = currentTrainers
        .map((trainer) => trainer.TrainerName)
        .join(", ");
      setUserAvatar(
        currentTrainers.length === 1 ? currentTrainers[0].Avatarfile : ""
      );
    }

    // Set Survey Name
    if (filter.Survey.length > 0) {
      const currentSurveys = surveysArray.filter((survey) =>
        filter.Survey.includes(survey.id)
      );
      filterNameTemp.Survey = currentSurveys
        .map((survey) => survey.Survey)
        .join(", ");
    }

    // Set Course Name
    if (filter.Course.length > 0) {
      const currentCourses = coursesArray.filter((course) =>
        filter.Course.includes(course.id)
      );
      filterNameTemp.Course = currentCourses
        .map((course) => course.Kurs)
        .join(", ");
    }

    // Set Question Name
    if (filter.Question.length > 0) {
      const currentQuestions = questionsArray.filter((question) =>
        filter.Question.includes(question.id)
      );
      filterNameTemp.Question = currentQuestions
        .map((question) => question.Fragenbeschreibung)
        .join(", ");
    }

    // Set Category Name
    if (filter.Category.length > 0) {
      const currentCategories = categoriesArray.filter((category) =>
        filter.Category.includes(category.id)
      );
      filterNameTemp.Category = currentCategories
        .map((category) => category.Category)
        .join(", ");
    }

    // Set Location Name
    if (filter.Location.length > 0) {
      const currentLocations = locationsArray.filter((location) =>
        filter.Location.includes(location.id)
      );
      filterNameTemp.Location = currentLocations
        .map((location) => location.LocationName)
        .join(", ");
    }

    // Set Course Number
    if (filter.Coursenumber.length > 0) {
      const currentCourseNumbers = courseNumbersArray.filter((courseNumber) =>
        filter.Coursenumber.includes(courseNumber.id)
      );
      filterNameTemp.Coursenumber = currentCourseNumbers
        .map((courseNumber) => courseNumber.Coursenumber)
        .join(", ");
    }

    // Set Discipline Name
    if (filter.Discipline.length > 0) {
      const currentDisciplines = disciplinesArray.filter((discipline) =>
        filter.Discipline.includes(discipline.id)
      );
      filterNameTemp.Discipline = currentDisciplines
        .map((discipline) => discipline.Discipline)
        .join(", ");
    }

    setFilterName(filterNameTemp);
  }, [allFeedbackData, filter, target]);

  // Update filter options based on dependencies
  useEffect(() => {
    const activeFilters = Object.entries(filter).filter(
      ([_, value]) => value !== "" && value.length !== 0
    );

    // Create new filters object
    const newFilters = activeFilters.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    // Add questionValue to filters if Question filter exists
    if (
      newFilters.Question?.length &&
      filterState.filters?.questionValue !== undefined
    ) {
      newFilters.questionValue = filterState.filters.questionValue;
    }

    updateFilters({
      target,
      filters: newFilters,
      dateRange: { start: startDate, end: endDate },
    });
  }, [filter, target, startDate, endDate, updateFilters]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Adjust trainer options based on target
  useEffect(() => {
    setTrainerOptions(allUserOptions);
  }, [target, allUserOptions]);

  function onTrainerChange(newValue) {
    updateState(
      "Trainer",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Trainer",
      newValue.map((option) => option.TrainerName).join(", "),
      setFilterName
    );
    setSelectedTrainers(newValue);
  }

  function onCourseChange(newValue) {
    updateState(
      "Course",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Course",
      newValue.map((option) => option.Kurs),
      setFilterName
    );
  }

  function onQuestionChange(newValue) {
    // Clear both Question and questionValue in filterState
    updateFilters({
      filters: {
        ...filterState.filters,
        Question: newValue.map((option) => option.id),
        questionValue: undefined, // Clear questionValue when question changes
      },
    });

    // Update local filter state
    updateState(
      "Question",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Question",
      newValue.map((option) => option.Fragenbeschreibung),
      setFilterName
    );

    // Get stored state and remove questionValue
    const storedState = localStorage.getItem("feedbackContextState");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      if (parsedState.filters) {
        delete parsedState.filters.questionValue;
        localStorage.setItem(
          "feedbackContextState",
          JSON.stringify(parsedState)
        );
      }
    }
  }

  function onCategoryChange(newValue) {
    updateState(
      "Category",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Category",
      newValue.map((option) => option.Category),
      setFilterName
    );
  }

  function onSurveyChange(newValue) {
    updateState(
      "Survey",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Survey",
      newValue.map((option) => option.Survey),
      setFilterName
    );
  }

  function onLocationChange(newValue) {
    updateState(
      "Location",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Location",
      newValue.map((option) => option.LocationName),
      setFilterName
    );
  }

  function onCourseNumberChange(newValue) {
    updateState(
      "Coursenumber",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Coursenumber",
      newValue.map((option) => option.Coursenumber),
      setFilterName
    );
  }

  function onDisciplineChange(newValue) {
    updateState(
      "Discipline",
      newValue.map((option) => option.id),
      setFilter
    );
    updateState(
      "Discipline",
      newValue.map((option) => option.Discipline),
      setFilterName
    );
  }

  const clearFilters = useCallback(() => {
    setFilter({
      Category: [],
      Survey: [],
      Trainer: [],
      Course: [],
      Location: [],
      Coursenumber: [],
      Discipline: [],
      Question: [],
    });
    setFilterName({
      Category: "",
      Survey: "",
      Trainer: "",
      Course: "",
      Location: "",
      Coursenumber: "",
      Discipline: "",
      Question: "",
    });
  }, [setFilter, setFilterName]);
  
    useEffect(() => {
      if(filter.Question[0]){
      sendGetRequest("system/evaluationOptions").then((response) => {
        sendGetRequest("question?id=" + filter.Question[0]).then((questionDetails) => {
          generateJson(
            questionDetails,
            setJson,
            SurveyJsQuestion,
            response,
            () => {}
          );
        });
      });
    }

    }, [filter.Question]);


  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"start"}
      className="filter"
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"start"}
        flexWrap="wrap"
        useFlexGap
        spacing={1}
        style={{ minWidth: "70%" }}
      >
        <Button
          id="filter-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          startIcon={<FaFilter size="15px" />}
        >
          Filter
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
          className="filter"
        >
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <TabList onChange={handleChange} aria-label="Filter">
                <Tab label="Details" value="1" />
                <Tab label="Fragen" value="2" />
              </TabList>
              <TabPanel value="1">
                {" "}
                {currentUserData.permissions.includes(4) && (
                  <FilterSelect
                    id={filter.Trainer}
                    onChange={onTrainerChange}
                    filterName="TrainerName"
                    options={trainerOptions}
                    label={
                      target === "Kursteilnehmer" ? "Trainer/-in" : "Benutzer"
                    }
                    width="100%"
                  />
                )}
                <FilterSelect
                  id={filter.Course}
                  onChange={onCourseChange}
                  filterName="Kurs"
                  options={courseOptions}
                  label={"Kurse"}
                  width="100%"
                />
                <FilterSelect
                  id={filter.Category}
                  onChange={onCategoryChange}
                  filterName="Category"
                  options={categoryOptions}
                  label={"Kategorien"}
                  width="100%"
                />
                <FilterSelect
                  id={filter.Survey}
                  onChange={onSurveyChange}
                  filterName="Survey"
                  options={surveyOptions}
                  label={"Fragebögen"}
                  width="100%"
                />
                <FilterSelect
                  id={filter.Coursenumber}
                  onChange={onCourseNumberChange}
                  filterName="Coursenumber"
                  options={courseNumberOptions}
                  label={"Kursnummer"}
                  width="100%"
                />
                <FilterSelect
                  id={filter.Discipline}
                  onChange={onDisciplineChange}
                  filterName="Discipline"
                  options={disciplineOptions}
                  label={"Fachbereich"}
                  width="100%"
                />
                {isLocationActive && (
                  <FilterSelect
                    id={filter.Location}
                    onChange={onLocationChange}
                    filterName="LocationName"
                    options={locationOptions}
                    label={"Standorte"}
                    width="100%"
                  />
                )}
              </TabPanel>
              <TabPanel value="2">
                <FilterSelect
                  id={filter.Question}
                  onChange={onQuestionChange}
                  filterName="Fragenbeschreibung"
                  options={questionOptions}
                  label={"Frage"}
                  width="100%"
                  multiple={false}
                />
                Filtere alle Fragebögen, in denen diese Frage wie folgt
                beantwortet wurde:{" "}
                {filter.Question[0] && (
                  <SurveyElement 
                    questionId={filter.Question[0]} 
                    json={json}
                    preview={true}
                    className="questionPreview" 
                  />
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </StyledMenu>
        <Stack
          color="primary"
          direction="row"
          alignItems="center"
          sx={{
            color: "primary.main",
            border: "0.8px solid",
            borderRadius: "16px",
            backgroundColor: "white",
            padding: "5px",
          }}
          spacing={1}
        >
          <CalendarMonthTwoToneIcon color="primary" />
          <DateRange
            setStartDate={setStartDate}
            startDate={startDate}
            setEndDate={setEndDate}
            endDate={endDate}
            color={"blue"}
          />
        </Stack>
        <FilterChips
          filter={filter}
          setFilter={setFilter}
          filterName={filterName}
          setFilterName={setFilterName}
          userAvatar={userAvatar}
          trainerAvatars={userAvatar}
          selectedTrainers={selectedTrainers}
        />
        <Tooltip title="CSV-Export">
          <FileDownloadIcon
            sx={{
              color: "primary.main",
              cursor: "pointer",
              fontSize: "28px",
            }}
            onClick={() => exportToCSV(feedbackData)}
          />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        {component ? (
          component
        ) : (
          <>
            <TargetButton
              target={target}
              setTarget={setTarget}
              setSurveyId={(value) => updateState("Survey", value, setFilter)}
              setSurveyName={(value) =>
                updateState("Survey", value, setFilterName)
              }
              clearFilters={clearFilters}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}
