import {
  MdOutlineTrendingDown,
  MdOutlineTrendingFlat,
  MdOutlineTrendingUp,
} from "react-icons/md";
import { updateState } from "../../Surveys/surveysFunctions";
import { sendGetRequest } from "../../../Common/sendGetRequest";
import wordsCount from "words-count"; //counts all words
import countWords from "count-words";
import {berechneNPS, berechneNPSTrend} from "./evaluationNumericFunctions"

export function berechneDurchschnittBewertung(data) {
  let sum = 0;
  let count = 0;

  data.forEach((item) => {
    const bewertung = parseFloat(item.Value);
    if (!isNaN(bewertung)) {
      sum += bewertung;
      count++;
    }
  });

  // Schutz vor Division durch undefined
  if (count === 0) {
    return 0;
  }
  const durchschnitt = sum / count;
  // Runden auf eine Dezimalstelle
  const gerundeterDurchschnitt = durchschnitt.toFixed(1);

  return parseFloat(gerundeterDurchschnitt); // Als Zahl zurückgeben
}

export function berechneTrend(data) {
  let sum = 0;
  let count = 0;
  let i = 0;
  let Durchschnitt = berechneDurchschnittBewertung(data);

  data.forEach((item) => {
    if (i < 10) {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        sum += bewertung;
        count++;
      }
      i++;
    }
  });

  // Schutz vor Division durch undefined
  if (count === 0) {
    return 0;
  }
  const durchschnittOfLast10 = sum / count;
  // Runden auf eine Dezimalstelle
  const Trend = durchschnittOfLast10.toFixed(1) - Durchschnitt;

  return parseFloat(Trend).toFixed(1); // Als Zahl zurückgeben
}

export function createSeriesForEvaluationPieChart(data) {
  let evaluationSeries = [0, 0, 0, 0, 0];
  data.forEach((item) => {
    if (item.Typ === "Bewertung") {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        evaluationSeries[bewertung - 1] += 1;
      }
    }
  });
  return evaluationSeries.reverse();
}

export function arrayWithObjectsNameSort(arrayToSort) {
  arrayToSort.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
}

export function arraySortFirstElement(arrayToSort) {
  arrayToSort.sort((a, b) => {
    const nameA = a[0].toUpperCase(); // Convert to uppercase for case-insensitive sorting
    const nameB = b[0].toUpperCase();

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
}

export function createObjectForTrainerPieChart(data) {
  const feedbackIDs = {};
  const feedbackSeries = {};

  data.forEach((feedback) => {
    const trainerID = feedback.Trainer;
    const trainerName = feedback.TrainerName;
    const feedbackID = feedback.id;

    if (!(feedbackID in feedbackIDs)) {
      if (trainerID in feedbackIDs) {
        feedbackSeries[trainerName]++;
      } else {
        feedbackIDs[trainerID] = true;
        feedbackSeries[trainerName] = 1;
      }
      feedbackIDs[feedbackID] = true;
    }
  });
  return feedbackSeries;
}

export function createObjectForCoursePieChart(data) {
  const feedbackIDs = {};
  const feedbackSeries = {};

  data.forEach((feedback) => {
    const courseID = feedback.Course;
    const courseName = feedback.CourseName;
    const feedbackID = feedback.id;

    if (!(feedbackID in feedbackIDs)) {
      if (courseID in feedbackIDs) {
        feedbackSeries[courseName]++;
      } else {
        feedbackIDs[courseID] = true;
        feedbackSeries[courseName] = 1;
      }
      feedbackIDs[feedbackID] = true;
    }
  });
  return feedbackSeries;
}

export function combineAndAverageFeedback(feedbackArray) {
  const combinedFeedback = {};

  feedbackArray.forEach((feedback) => {
    if (feedback.Typ === "Bewertung" && !isNaN(feedback.Value)) {
      const feedbackID = feedback.id;
      const date = new Date(feedback.Date);
      // Create a combined key of feedbackID and date
      const combinedKey = `${date.getYear()}-${date.getMonth()}`; //Hier wird die Granularität der Auswertung definiert

      if (!combinedFeedback[combinedKey]) {
        // If the combinedFeedback object doesn't have an entry for this combined key, create one
        combinedFeedback[combinedKey] = {
          Typ: feedback.Typ,
          Date: date,
          id: feedbackID,
          Value: parseFloat(feedback.Value), // Assuming Value is a numeric property
          Count: 1,
        };
      } else {
        // If the entry already exists, update the average and count
        combinedFeedback[combinedKey].Value += parseFloat(feedback.Value);
        combinedFeedback[combinedKey].Count++;
      }
    }
  });

  // Calculate the average for each entry
  for (const combinedKey in combinedFeedback) {
    combinedFeedback[combinedKey].Value /= combinedFeedback[combinedKey].Count;
  }

  // Convert the combinedFeedback object back to an array
  const resultArray = Object.values(combinedFeedback);

  // Custom sorting export function for datetime
  function compareDatetime(a, b) {
    const dateA = new Date(a.Date);
    const dateB = new Date(b.Date);
    return dateA - dateB;
  }

  // Sort the array using the custom export function
  resultArray.sort(compareDatetime);
  return resultArray;
}

export function createObjectForTimelineChart(data) {
  data = combineAndAverageFeedback(data);
  const feedbackData = [];

  data.forEach((feedback) => {
    if (feedback.Typ === "Bewertung" && !isNaN(feedback.Value)) {
      const myDate = new Date(feedback.Date);

      const year = myDate.getFullYear();
      const month = myDate.getMonth() + 1; // Adding 1 to get a one-based month

      // Format the result as 'YYYY-MM'
      const yearMonthString = `${year}-${month.toString().padStart(2, "0")}`;

      // Create a new Date object from the formatted string
      const yearMonthDate = new Date(yearMonthString);
      const newFeedback = {
        x: yearMonthDate,
        y: parseFloat(feedback.Value).toFixed(1),
      };
      feedbackData.push(newFeedback);
    }
  });
  
  return feedbackData;
}

export function getNumberForTimelineChart(data) {
  const feedbackData = [];
  const yearMonthArray = [];
  const feedbackIDs = [];
  let i = 0;

  data.forEach((feedback) => {
    feedbackIDs.push(feedback.id);
    const myDate = new Date(feedback.Date);
    const year = myDate.getFullYear();
    const month = myDate.getMonth() + 1; // Adding 1 to get a one-based month
    const yearMonthString = `${year}-${month.toString().padStart(2, "0")}`;

    const yearMonthDate = new Date(yearMonthString);
    if (!yearMonthArray.includes(yearMonthString)) {
      yearMonthArray.push(yearMonthString);
      const newFeedback = {
        x: yearMonthDate,
        y: 1,
      };
      feedbackData[i + 1] = newFeedback;
      i++;
    } else {
      feedbackData[i].y++;
    }
  });
  const filteredArray = feedbackData.filter(Boolean).reverse();
  
  return filteredArray;
}

export function countNumberOfFeedback(data) {
  let numberOfFeedback = 0;
  for (let i of data) {
      numberOfFeedback++;
  }
  return numberOfFeedback;
}

export function calculateAverageWordCount(data, questionId) {
  data = data.filter((item) => item.ID === questionId)
  let numberOfFeedback = countNumberOfFeedback(data);
  let numberOfWords = 0;
  for (let i of data) {
    if (i.Typ === "Text" && i.ID === questionId) {
      numberOfWords = numberOfWords + wordsCount(i.Value);
    }
  }
  let averageWordCount =
    numberOfFeedback > 0 ? (numberOfWords / numberOfFeedback).toFixed(1) : 0;
  return averageWordCount;
}

export function calculateAverageWordCountLast10(data, questionId) {
  let numberOfFeedback = 0;
  let averageWordCount = calculateAverageWordCount(data, questionId);
  let numberOfWords = 0;
  for (let i of data) {
    if (numberOfFeedback <= 10) {
      if (i.Typ === "Text" && i.ID === questionId) {
        numberOfWords = numberOfWords + wordsCount(i.Value);
        numberOfFeedback++;
      }
    } else break;
  }
  let averageWordCountLast10 = (numberOfWords / numberOfFeedback).toFixed(1);
  let trend = (averageWordCountLast10 - averageWordCount).toFixed(1);
  return trend < 0 ? (
    <>
      <MdOutlineTrendingDown style={{ color: "red", fontSize: "20px" }} />
      {"  " + trend}
    </>
  ) : trend > 0 ? (
    <>
      <MdOutlineTrendingUp style={{ color: "green", fontSize: "20px" }} />
      {"+ " + trend}
    </>
  ) : (
    <>
      <MdOutlineTrendingFlat
        style={{ color: "lightgreen", fontSize: "20px" }}
      />
      {"  " + trend}
    </>
  );
}

export function countNumberOfFeedbackForCurrentMonth(data) {
  let currentMonth = new Date().getMonth();
  let numberOfFeedback = 0;
  for (let i of data) {
    if (new Date(i.Date).getMonth() === currentMonth) {
      numberOfFeedback++;
    }
  }
  return numberOfFeedback;
}

export function createObjectForBewertungPieChart(data) {
  let rankingJson = {};
  let rankingOptions = [];
  data.forEach((element) => {
    rankingOptions = JSON.parse(element.Value);
    rankingOptions.forEach((element) => {
      if (rankingJson[element] === undefined) {
        rankingJson[element] = 1;
      } else {
        rankingJson[element] = rankingJson[element] + 1;
      }
    });
  });

  return rankingJson;
}

export function createObjectForRankingPieChart(data) {
  let rankingJson = {};
  let rankingOptions = [];
  data.forEach((element) => {
    rankingOptions = JSON.parse(element.Value).reverse();
    rankingOptions.forEach((element, index) => {
      if (rankingJson[element] === undefined) {
        rankingJson[element] = index;
      } else {
        rankingJson[element] = rankingJson[element] + index;
      }
    });
  });

  return rankingJson;
}

export function createObjectForBewertungPieChartRadio(data) {
  let rankingJson = {};
  let rankingOption = "";
  data.forEach((element) => {
    rankingOption = element.Value.slice(1, -1);
    if (rankingJson[rankingOption] === undefined) {
      rankingJson[rankingOption] = 1;
    } else {
      rankingJson[rankingOption] = rankingJson[rankingOption] + 1;
    }
  });

  return rankingJson;
}

export function createSeriesForBewertungPieChart(inputObject) {
  const keyValueArray = Object.entries(inputObject);
  arraySortFirstElement(keyValueArray);

  let series = [];
  let labels = [];
  for (let i in keyValueArray) {
    series.push(keyValueArray[i][1]);
    labels.push(keyValueArray[i][0]);
  }
  return { labels: labels, series: series };
}

export function createSeriesForTrainerPieChart(inputObject) {
  const namesArray = [];
  const numbersArray = [];

  for (const [name, number] of Object.entries(inputObject)) {
    namesArray.push(name);
    numbersArray.push(number);
  }

  return { labels: namesArray, series: numbersArray };
}

export function createSeriesForCoursePieChart(inputObject) {
  const namesArray = [];
  const numbersArray = [];

  for (const [name, number] of Object.entries(inputObject)) {
    namesArray.push(name);
    numbersArray.push(number);
  }

  return { labels: namesArray, series: numbersArray };
}

export function calculateDaysSinceLastFeedback(data) {
  let latestDate = new Date(data[0].Date);
  let today = new Date();

  const timeDifferenceInMilliseconds = new Date() - latestDate;

  const timeDifferenceInDays = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  let textToShow = `vor ${timeDifferenceInDays} Tagen`;
  if (timeDifferenceInDays === 0 || timeDifferenceInDays === 1) {
    if (today.getDate() != latestDate.getDate()) {
      textToShow = "gestern";
    } else textToShow = "heute";
  }

  return textToShow;
}

export function Next(diagramID) {
  const nextButton = document.querySelector(
    diagramID + ">.carousel-control-next"
  );
  if (nextButton) {
    nextButton.click();
  }
}

export function Prev(diagramID) {
  const nextButton = document.querySelector(
    diagramID + ">.carousel-control-prev"
  );
  if (nextButton) {
    nextButton.click();
  }
}

export function getDurchschnittForQuestion(feedbackData, questionId) {
  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    let average = berechneDurchschnittBewertung(filteredData);
    return average;
  } else {
    return "";
  }
}

export function getNPSForQuestion(feedbackData, questionId) {
  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    let averageNPS = berechneNPS(filteredData);
    return averageNPS;
  } else {
    return "";
  }
}

export function getTrendForQuestion(feedbackData, questionId) {
  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    let trend = berechneTrend(filteredData);
    return trend < 0 ? (
      <>
        <MdOutlineTrendingDown style={{ color: "red", fontSize: "20px" }} />
        {"  " + trend}
      </>
    ) : trend > 0 ? (
      <>
        <MdOutlineTrendingUp style={{ color: "green", fontSize: "20px" }} />
        {"+ " + trend}
      </>
    ) : (
      <>
        <MdOutlineTrendingFlat
          style={{ color: "lightgreen", fontSize: "20px" }}
        />
        {"  " + trend}
      </>
    );
  }
}

export function getNPSTrendForQuestion(feedbackData, questionId) {
  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    let trend = berechneNPSTrend(filteredData);
    return trend < 0 ? (
      <>
        <MdOutlineTrendingDown style={{ color: "red", fontSize: "20px" }} />
        {"  " + trend}
      </>
    ) : trend > 0 ? (
      <>
        <MdOutlineTrendingUp style={{ color: "green", fontSize: "20px" }} />
        {"+ " + trend}
      </>
    ) : (
      <>
        <MdOutlineTrendingFlat
          style={{ color: "lightgreen", fontSize: "20px" }}
        />
        {"  " + trend}
      </>
    );
  }
}

export function getMostChosenOption(feedbackData, questionId) {
  let rankingJson = {};
  let rankingOptions = [];

  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    filteredData.forEach((element) => {
      rankingOptions = JSON.parse(element.Value);
      rankingOptions.forEach((element) => {
        if (rankingJson[element] === undefined) {
          rankingJson[element] = 1;
        } else {
          rankingJson[element] = rankingJson[element] + 1;
        }
      });
    });

    const keyValueArray = Object.entries(rankingJson);
    arraySortFirstElement(keyValueArray);

    const mostOftenChosenOption = keyValueArray.reduce(
      (maxArray, currentArray) => {
        return currentArray[1] > maxArray[1] ? currentArray : maxArray;
      },
      keyValueArray[0]
    );

    return mostOftenChosenOption;
  }
}

export function getMostChosenRankingOption(feedbackData, questionId) {
  let rankingJson = {};
  let rankingOptions = [];

  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    filteredData.forEach((element) => {
      rankingOptions = JSON.parse(element.Value).reverse();
      rankingOptions.forEach((element, index) => {
        if (rankingJson[element] === undefined) {
          rankingJson[element] = index;
        } else {
          rankingJson[element] = rankingJson[element] + index;
        }
      });
    });

    const keyValueArray = Object.entries(rankingJson);
    arraySortFirstElement(keyValueArray);

    const mostOftenChosenOption = keyValueArray.reduce(
      (maxArray, currentArray) => {
        return currentArray[1] > maxArray[1] ? currentArray : maxArray;
      },
      keyValueArray[0]
    );

    return mostOftenChosenOption;
  }
}

export function getMostChosenOptionLast10(feedbackData, questionId) {
  let rankingJson = {};
  let rankingOptions = [];

  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    filteredData.slice(0, 10).forEach((element) => {
      rankingOptions = JSON.parse(element.Value);
      rankingOptions.forEach((element) => {
        if (rankingJson[element] === undefined) {
          rankingJson[element] = 1;
        } else {
          rankingJson[element] = rankingJson[element] + 1;
        }
      });
    });

    const keyValueArray = Object.entries(rankingJson);
    arraySortFirstElement(keyValueArray);

    const mostOftenChosenOption = keyValueArray.reduce(
      (maxArray, currentArray) => {
        return currentArray[1] > maxArray[1] ? currentArray : maxArray;
      },
      keyValueArray[0]
    );

    return (
      <>
        <MdOutlineTrendingUp style={{ color: "green", fontSize: "20px" }} />
        {" " + mostOftenChosenOption[0]}
      </>
    );
  }
}

export function getMostChosenRankingOptionLast10(feedbackData, questionId) {
  let rankingJson = {};
  let rankingOptions = [];

  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    filteredData.slice(0, 10).forEach((element) => {
      rankingOptions = JSON.parse(element.Value).reverse();
      rankingOptions.forEach((element, index) => {
        if (rankingJson[element] === undefined) {
          rankingJson[element] = index;
        } else {
          rankingJson[element] = rankingJson[element] + index;
        }
      });
    });

    const keyValueArray = Object.entries(rankingJson);
    arraySortFirstElement(keyValueArray);

    const mostOftenChosenOption = keyValueArray.reduce(
      (maxArray, currentArray) => {
        return currentArray[1] > maxArray[1] ? currentArray : maxArray;
      },
      keyValueArray[0]
    );

    return (
      <>
        <MdOutlineTrendingUp style={{ color: "green", fontSize: "20px" }} />
        {" " + mostOftenChosenOption[0]}
      </>
    );
  }
}

export function getMostChosenOptionRadio(feedbackData, questionId) {
  let rankingJson = {};
  let rankingOption = "";

  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    filteredData.forEach((element) => {
      rankingOption = element.Value.slice(1, -1);
      if (rankingJson[rankingOption] === undefined) {
        rankingJson[rankingOption] = 1;
      } else {
        rankingJson[rankingOption] = rankingJson[rankingOption] + 1;
      }
    });

    const keyValueArray = Object.entries(rankingJson);
    arraySortFirstElement(keyValueArray);

    const mostOftenChosenOption = keyValueArray.reduce(
      (maxArray, currentArray) => {
        return currentArray[1] > maxArray[1] ? currentArray : maxArray;
      },
      keyValueArray[0]
    );

    return mostOftenChosenOption;
  }
}

export function getMostChosenOptionRadioLast10(feedbackData, questionId) {
  let rankingJson = {};
  let rankingOption = "";

  let filteredData = Object.values(feedbackData).filter(
    (obj) => obj.ID === questionId
  );
  if (filteredData.length > 0) {
    filteredData.slice(0, 10).forEach((element) => {
      rankingOption = element.Value.slice(1, -1);
      if (rankingJson[rankingOption] === undefined) {
        rankingJson[rankingOption] = 1;
      } else {
        rankingJson[rankingOption] = rankingJson[rankingOption] + 1;
      }
    });

    const keyValueArray = Object.entries(rankingJson);
    arraySortFirstElement(keyValueArray);

    const mostOftenChosenOption = keyValueArray.reduce(
      (maxArray, currentArray) => {
        return currentArray[1] > maxArray[1] ? currentArray : maxArray;
      },
      keyValueArray[0]
    );

    return (
      <>
        <MdOutlineTrendingUp style={{ color: "green", fontSize: "20px" }} />
        {" " + mostOftenChosenOption[0]}
      </>
    );
  }
}

export function getQuestionDescription(questionList, questionId) {
  let objWithID189 = questionList.find((obj) => obj.ID == questionId);
  let fragenbeschreibung = objWithID189 ? objWithID189.Fragenbeschreibung : "";
  return fragenbeschreibung;
}

export async function loadFeedbackData(
  setDataFetched,
  setFeedbackData,
  setAllFeedbackData = ((x) => {}),
  target = "Kursteilnehmer",
  setTarget = (x)=>{},
  surveyId = false
) {
  setDataFetched(false);
  sendGetRequest(
    "feedback"
  ).then((response) => {
    let newTarget = target
    if(surveyId)
    {
      let survey = response.filter((feedback)=>feedback.id===parseInt(surveyId))
      newTarget = survey[0].Target;
      setTarget(newTarget)
    }
    setFeedbackData(response.filter((feedback)=>feedback.Target===newTarget));
    setAllFeedbackData(response);
    setDataFetched(true);
  });
}

export function getUniqueById(dataArray) {
  const seenIds = new Set();
  return dataArray.filter(item => {
    if (seenIds.has(item.id)) {
      return false;
    } else {
      seenIds.add(item.id);
      return true;
    }
  });
}

function parseGermanDate(dateStr) {
  try {
    // Expecting "dd.mm.yyyy"
    const [day, month, year] = dateStr.split('.');
    const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    
    // Validate the parsed date
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return date;
  } catch (error) {
    console.error('Error parsing German date:', dateStr, error);
    return new Date(); // Return current date as fallback
  }
}

function parseObjectDate(dateStr) {
  try {
    // Object date format: "yyyy-mm-dd HH:mm:ss"
    const [datePart, timePart] = dateStr.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart ? timePart.split(':') : [0, 0, 0];
    
    const date = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
      parseInt(hours, 10),
      parseInt(minutes, 10),
      parseInt(seconds, 10)
    );

    // Validate the parsed date
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return date;
  } catch (error) {
    console.error('Error parsing object date:', dateStr, error);
    return new Date(); // Return current date as fallback
  }
}

function formatGermanDate(dateObj) {
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();
  return `${day}.${month}.${year}`;
}

export function getOldestDate(dataArray) {
  if (dataArray.length === 0) return null;

  const timestamps = dataArray.map(item => parseObjectDate(item.Date).getTime());
  const oldestTimestamp = Math.min(...timestamps);
  
  const oldestDate = new Date(oldestTimestamp);
  return formatGermanDate(oldestDate);
}

function ensureDate(dateValue) {
  try {
    if (dateValue instanceof Date) {
      if (isNaN(dateValue.getTime())) {
        throw new Error('Invalid Date object');
      }
      return dateValue;
    } else if (typeof dateValue === 'string') {
      return parseGermanDate(dateValue);
    } else {
      throw new Error('Invalid date format');
    }
  } catch (error) {
    console.error('Error ensuring date:', dateValue, error);
    return new Date(); // Return current date as fallback
  }
}

export function filterFeedbackData(dataArray, startDate, endDate) {
  try {
    const parsedStartDate = ensureDate(startDate);
    const parsedEndDate = ensureDate(endDate);

    parsedStartDate.setHours(0, 0, 0, 0);
    parsedEndDate.setHours(23, 59, 59, 999);

    console.log("Filtering data between:", 
      parsedStartDate.toLocaleString(),
      "and",
      parsedEndDate.toLocaleString()
    );

    return dataArray.filter(item => {
      try {
        const itemDate = parseObjectDate(item.Date);
        const isInRange = itemDate >= parsedStartDate && itemDate <= parsedEndDate;
        return isInRange;
      } catch (error) {
        console.error("Error filtering item:", item, error);
        return false;
      }
    });
  } catch (error) {
    console.error("Error in filterFeedbackData:", error);
    return dataArray;
  }
}

export function questionFilter(filter, feedbackData, setQuestionType) {
  if (filter.question !== "" && feedbackData.length > 0) {
    setQuestionType(feedbackData[0].Typ);
    if (!window.location.pathname.includes("question")) {
      addQuestionIdToPath(window.location.pathname, filter.question);
    }
  } else {
    setQuestionType("Bewertung");
  }
}

export function getAllQuestions(target, setAllQuestionsList, setDataFetched) {
  sendGetRequest("questions?target=" + target).then((response) => {
    setAllQuestionsList(response);
    setDataFetched(true)
  });
}

export function getFilteredQuestions(
  allFeedbackData,
  allQuestionsList,
  target
) {
  const targetFeedback = allFeedbackData.filter((obj) => obj.Target === target);
  const uniqueQuestionIDs = [
    ...new Set(targetFeedback.map((obj) => obj.QuestionID)),
  ];
  const questionsWithFeedback = allQuestionsList.filter((obj) =>
    uniqueQuestionIDs.includes(obj.id)
  );
  return questionsWithFeedback;
}

export function addQuestionIdToPath(path, number) {
  const normalizedPath = path.endsWith("/") ? path : path + "/question/";
  const newPath = normalizedPath + number;
  window.history.replaceState({}, "", newPath);

  return newPath;
}

export function addSurveyIdToPath(number) {
  if(number === undefined)
  {
    window.history.replaceState({}, "", "/evaluation/surveys");
  }
  else{
    window.history.replaceState({}, "", "/evaluation/surveys/"+number);
  }
  return null;
}

export function countSpecificWords(data) {
  let allCommentsAsString = "";
  data.map((formData) => (allCommentsAsString += " "+formData.Value.slice(1, -1)));
  const wordCloudArray = Object.keys(countWords(allCommentsAsString))
    .map((key) => {
      const word = key;
      const count = countWords(allCommentsAsString)[key];

      // Check if the word has more than 3 characters
      if (word.length > 3 || word === "gut") {
        return {
          value: word,
          count: count,
        };
      } else {
        // If the word has 3 or fewer characters, return null or an empty object
        // depending on how you want to handle it
        return null;
      }
    })
    .filter((wordObj) => wordObj !== null); // Filter out null entries

  // Now wordCloudArray only contains words with more than 3 characters

  return wordCloudArray;
}
