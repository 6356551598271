import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { sendGetRequest } from "../../Common/sendGetRequest";
import { useLocation } from "react-router-dom";
import { filterFeedbackData } from "./Evaluation/evaluationFunctions";
import { useLocalStorage } from "../../Common/useLocalStorage";

const FeedbackContext = createContext();

export const useFeedback = () => useContext(FeedbackContext);

export const FeedbackProvider = ({ children }) => {
  const [rawFeedbackData, setRawFeedbackData] = useState([]);
  const [filterState, setFilterState] = useLocalStorage(
    "feedbackContextState",
    {
      target: "Kursteilnehmer",
      filters: {},
      dateRange: { start: null, end: null },
    }
  );
  const [dataFetched, setDataFetched] = useState(false);
  const previousFilteredData = useRef(null);
  const location = useLocation();

  const feedbackRoutes = [
    "/dashboard",
    "/evaluation/questions",
    "/evaluation/surveys",
    "/comments",
    "/heatmap",
  ];

  const applyFilters = useCallback((data, filters) => {
    if (!data?.length) return [];

    // First filter by target
    let filtered = data.filter((item) => item.Target === filters.target);

    if (!filters.filters) return filtered;

    if (filters.dateRange.start && filters.dateRange.end) {
      filtered = filterFeedbackData(filtered, filters.dateRange.start, filters.dateRange.end);
    }

    //if the lines above are enabled the filter after question answers are not working

    // Handle each filter type
    Object.entries(filters.filters).forEach(([key, value]) => {
      if (key === "Question") {
        if (value && value.length) {
          const questionID = value[0];
          const questionValue = filters.filters.questionValue;

          // First find matching answers
          const matchingAnswers = filtered.filter((item) => {
            const isQuestionMatch =
              Number(item.QuestionID) === Number(questionID);
            const isValueMatch =
              !questionValue ||
              String(item.ValueOption).includes(String(questionValue));
            return isQuestionMatch && isValueMatch;
          });

          // Get unique feedback IDs
          const feedbackIDs = new Set(
            matchingAnswers.map((item) => item.CoursefeedbackID)
          );

          // Keep all entries from matching feedback sessions
          if (feedbackIDs.size > 0) {
            filtered = data.filter((item) =>
              feedbackIDs.has(item.CoursefeedbackID)
            );
          } else {
            filtered = [];
          }
        }
      } else if (value && value.length) {
        if (key !== "questionValue") {
          filtered = filtered.filter((item) => value.includes(item[key]));
        }
      }
    });
    return filtered;
  }, []);

  const feedbackData = useMemo(() => {
    const newFilteredData = applyFilters(rawFeedbackData, filterState);

    // Compare with previous filtered data to prevent unnecessary updates
    if (
      JSON.stringify(newFilteredData) ===
      JSON.stringify(previousFilteredData.current)
    ) {
      return previousFilteredData.current;
    }

    previousFilteredData.current = newFilteredData;
    return newFilteredData;
  }, [rawFeedbackData, filterState, applyFilters]);

  const loadFeedbackData = useCallback(async () => {
    try {
      const response = await sendGetRequest("feedback");
      setRawFeedbackData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(response)) {
          return response;
        }
        return prevData;
      });
      setDataFetched(true);
    } catch (error) {
      console.error("Error fetching feedback:", error);
      setDataFetched(false);
    }
  }, []);

  useEffect(() => {
    const shouldFetchData = feedbackRoutes.some((route) =>
      location.pathname.startsWith(route)
    );

    if (shouldFetchData) {
      loadFeedbackData();
      const interval = setInterval(loadFeedbackData, 60000);
      return () => clearInterval(interval);
    }
  }, []);

  const updateFilters = useCallback((newFilters) => {
    setFilterState((prev) => {
      // Only update target if explicitly provided and different from current
      const target = newFilters.target !== undefined ? newFilters.target : prev.target;
      
      return {
        ...prev,
        target,
        filters: newFilters.filters || prev.filters,
        dateRange: newFilters.dateRange || prev.dateRange,
      };
    });
  }, []);

  const updateFeedbackData = (newData) => {
    setRawFeedbackData(newData);
  };

  const contextValue = useMemo(
    () => ({
      feedbackData,
      allFeedbackData: rawFeedbackData,
      dataFetched,
      updateFilters,
      filterState,
      updateFeedbackData,
    }),
    [feedbackData, rawFeedbackData, dataFetched, updateFilters, filterState]
  );

  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
    </FeedbackContext.Provider>
  );
};
