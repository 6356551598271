import * as React from "react";
import Chip from "@mui/material/Chip";
import { updateState } from "../../Surveys/surveysFunctions";
import UserAvatar from "../../Users/UserAvatar";
import LocalLibraryTwoToneIcon from "@mui/icons-material/LocalLibraryTwoTone";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import useQueryParams from "../../../Common/useQueryParams";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import ConfirmationNumberTwoToneIcon from "@mui/icons-material/ConfirmationNumberTwoTone";
import HelpOutlineTwoToneIcon from "@mui/icons-material/HelpOutlineTwoTone"; // Add this import

export default function FilterChips(props) {
  const { removeQueryParam } = useQueryParams();
  const { selectedTrainers } = props;

  // Hilfsfunktion zum sicheren Aufteilen basierend auf dem Filtertyp
  const splitFilterValues = (key, value) => {
    // Für Fragen nie bei Kommas aufteilen, einfach als ein einzelner Wert behandeln
    if (key === 'Question') {
      return [value];
    }
    // Für andere Filter wie bisher vorgehen
    return Array.isArray(value) ? value : value.split(", ");
  };

  const renderTrainerChips = () => {
    if (!props.filterName.Trainer) return null;
    const values = splitFilterValues('Trainer', props.filterName.Trainer);
    
    // Create a map of trainer names to their data
    const trainerMap = selectedTrainers.reduce((acc, trainer) => {
      acc[trainer.TrainerName] = trainer;
      return acc;
    }, {});
    
    return values.map((val, index) => {
      const trainer = trainerMap[val];
      return (
        <Chip
          key={`Trainer-${trainer?.id || index}`}
          icon={
            <UserAvatar
              name={val}
              src={trainer?.Avatarfile}
              marginLeft={"10px"}
              marginRight={"0px"}
              width="30px"
              height="30px"
            />
          }
          label={val}
          color="primary"
          variant="outlined"
          onDelete={() => handleDelete("Trainer", index)}
          style={chipStyle}
        />
      );
    });
  };

  const renderCourseChips = () => {
    if (!props.filterName.Course) return null;
    const values = splitFilterValues('Course', props.filterName.Course);
    
    return values.map((val, index) => (
      <Chip
        key={`Course-${index}`}
        icon={<LocalLibraryTwoToneIcon />}
        label={val}
        color="primary"
        variant="outlined"
        onDelete={() => handleDelete("Course", index)}
        style={chipStyle}
      />
    ));
  };

  const renderCategoryChips = () => {
    if (!props.filterName.Category) return null;
    const values = splitFilterValues('Category', props.filterName.Category);
    
    return values.map((val, index) => (
      <Chip
        key={`Category-${index}`}
        icon={<CategoryTwoToneIcon />}
        label={val}
        color="primary"
        variant="outlined"
        onDelete={() => handleDelete("Category", index)}
        style={chipStyle}
      />
    ));
  };

  const renderSurveyChips = () => {
    if (!props.filterName.Survey) return null;
    const values = splitFilterValues('Survey', props.filterName.Survey);
    
    return values.map((val, index) => (
      <Chip
        key={`Survey-${index}`}
        icon={<AssignmentTwoToneIcon />}
        label={val}
        color="primary"
        variant="outlined"
        onDelete={() => handleDelete("Survey", index)}
        style={chipStyle}
      />
    ));
  };

  const renderLocationChips = () => {
    if (!props.filterName.Location) return null;
    const values = splitFilterValues('Location', props.filterName.Location);
    
    return values.map((val, index) => (
      <Chip
        key={`Location-${index}`}
        icon={<LocationOnTwoToneIcon />}
        label={val}
        color="primary"
        variant="outlined"
        onDelete={() => handleDelete("Location", index)}
        style={chipStyle}
      />
    ));
  };

  const renderCourseNumberChips = () => {
    if (!props.filterName.Coursenumber) return null;
    const values = splitFilterValues('Coursenumber', props.filterName.Coursenumber);
    
    return values.map((val, index) => (
      <Chip
        key={`Coursenumber-${index}`}
        icon={<ConfirmationNumberTwoToneIcon />}
        label={val}
        color="primary"
        variant="outlined"
        onDelete={() => handleDelete("Coursenumber", index)}
        style={chipStyle}
      />
    ));
  };

  const renderDisciplineChips = () => {
    if (!props.filterName.Discipline) return null;
    const values = splitFilterValues('Discipline', props.filterName.Discipline);
    
    return values.map((val, index) => (
      <Chip
        key={`Discipline-${index}`}
        icon={<LocalLibraryTwoToneIcon />}
        label={val}
        color="primary"
        variant="outlined"
        onDelete={() => handleDelete("Discipline", index)}
        style={chipStyle}
      />
    ));
  };

  const renderQuestionChips = () => {
    if (!props.filterName.Question) return null;
    
    // Besondere Behandlung für Fragen - keine Trennung bei Kommas
    const values = [props.filterName.Question];

    return values.map((val, index) => (
      <Chip
        key={`Question-${index}`}
        icon={<HelpTwoToneIcon />}
        label={val}
        color="primary"
        variant="outlined"
        onDelete={() => handleDelete("Question", index)}
        style={chipStyle}
      />
    ));
  };

  const handleDelete = (key, index) => {
    const newFilterValues = props.filter[key].filter((_, i) => i !== index);
    updateState(key, newFilterValues, props.setFilter);
    
    // Spezielle Behandlung für Fragen-Filter
    if (key === 'Question') {
      updateState(key, "", props.setFilterName);
    } else {
      const values = splitFilterValues(key, props.filterName[key]);
      const newFilterNames = values
        .filter((_, i) => i !== index)
        .join(", ");
      updateState(key, newFilterNames, props.setFilterName);
    }
    
    removeQueryParam(key.toLowerCase());
  };

  const chipStyle = {
    fontSize: "15px",
    padding: "18px 0px",
    maxWidth: "330px",
    backgroundColor: "white",
  };

  return (
    <>
      {renderTrainerChips()}
      {renderCourseChips()}
      {renderCategoryChips()}
      {renderSurveyChips()}
      {renderLocationChips()}
      {renderCourseNumberChips()}
      {renderDisciplineChips()}
      {renderQuestionChips()}
    </>
  );
}
