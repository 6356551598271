import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import UserAvatar from "../../Users/UserAvatar";
import Chip from "@mui/material/Chip";
import useQueryParams from "../../../Common/useQueryParams";

export default function FilterSelect({
  id,
  onChange,
  filterName,
  options = [],
  label = "Trainer/-in",
  width = "100%",
  multiple = true
}) {
  const { updateQueryParams } = useQueryParams();
  const isTrainerSelect = filterName === "TrainerName";

  return (
    <Autocomplete
      className="filterSelect"
      multiple={multiple}
      limitTags={2}
      id="multiple-limit-tags"
      options={options}
      value={multiple 
        ? options.filter((option) => id.includes(option.id))
        : options.find((option) => id.includes(option.id)) || null
      }
      onChange={(event, newValue) => {
        onChange(multiple ? newValue : [newValue].filter(Boolean));
      }}
      getOptionLabel={(option) => `${option[filterName]}`}
      sx={{
        width,
        '& .MuiAutocomplete-tag': {
          maxWidth: '250px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }
      }}
      renderOption={(props, option) => {
        const { key, ...otherProps } = props;
        return (
          <Box 
            key={key} 
            component="li" 
            sx={{ 
              "& > img": { mr: 1, flexShrink: 0 },
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }} 
            {...otherProps}
          >
            {isTrainerSelect && (
              <UserAvatar
                name={option[filterName]}
                src={option.Avatarfile}
                marginLeft={"10px"}
                marginRight={"10px"}
              />
            )}
            {option[filterName]}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              {...tagProps}
              label={option[filterName]}
              icon={
                isTrainerSelect ? (
                  <UserAvatar
                    name={option[filterName]}
                    src={option.Avatarfile}
                    marginLeft={"10px"}
                    marginRight={"0px"}
                    width="24px"
                    height="24px"
                  />
                ) : undefined
              }
            />
          );
        })
      }
    />
  );
}

