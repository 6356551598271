export const SurveyJsQuestion = {
  locale: "de",
  focusFirstQuestionAutomatic: false,
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "",
          name: "",
          title: {
            de: "",
            en: "",
          },
          description: { de: "", en: "" },
          rateType: "",
          rateCount: 5,
          rateMax: 5,
          choices: [],
          maxLength: 0,
          inputType: "",
          step: 1,
          rows: 5,
          rangeMin: 0,
          rangeMax: 100,
          min: 0,
          isRequired: false,
          max: 99,
          displayMode: "buttons",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
  showNavigationButtons: false
};
