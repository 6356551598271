import * as React from "react";
import { useState, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";
import UserAvatar from "../../Users/UserAvatar";
import Tooltip from "@mui/material/Tooltip";
import { formatDate } from "./dashboardEvaluationFunctions";
import { useNavigate } from "react-router-dom";

export default function FeedbackTimeline({ data, height="405px" }) {
  const navigate = useNavigate();

  // Filter data to include only unique id
  const uniqueData = Array.from(
    new Set(data.map((item) => item.id))
  ).map((courseFeedbackID) =>
    data.find((item) => item.id === courseFeedbackID)
  );

  // State to keep track of how many timeline items to show
  const [itemsToShow, setItemsToShow] = useState(10);

  // Navigate to survey detail page
  function navigateToSurveyDetails(surveyId) {
    navigate("../evaluation/surveys/" + surveyId);
  }

  // Scroll handler to load next 10 items when near bottom
  const handleScroll = useCallback(
    (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      // If the user is within 50px of the bottom, load more items
      if (scrollHeight - scrollTop - clientHeight < 50) {
        setItemsToShow((prev) => {
          if (prev < uniqueData.length) {
            return prev + 10;
          }
          return prev; // Already showing all items
        });
      }
    },
    [uniqueData]
  );

  return (
    <Paper
    className="paper"
    style={{ height: "100%", paddingRight: "0px", paddingBottom: "0px" }}
  >
    <header>Feedback Timeline</header>
    <div
      style={{
        height: height,
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop:"5px"
      }}
      onScroll={handleScroll}
    >
      <Timeline sx={{ paddingTop: "10px" }}>
        {uniqueData.slice(0, itemsToShow).map((item, index) => (
          <Tooltip key={index} title={"Trainer: " + item.TrainerName}>
            <TimelineItem
              sx={{ cursor: "pointer", width: "330px" }}
              onClick={() => navigateToSurveyDetails(item.id)}
            >
              <TimelineContent sx={{ m: "auto", overflow: "hidden" }}>
                <Typography variant="h6">
                  {item.CourseName}
                </Typography>
              </TimelineContent>
              <TimelineSeparator>
                <TimelineConnector />
                <UserAvatar name={item.TrainerName} src={item.Avatarfile} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineOppositeContent
                variant="body2"
                color="text.secondary"
                sx={{ maxWidth: "120px", margin: "auto" }}
              >
                {formatDate(item.Date)}
              </TimelineOppositeContent>
            </TimelineItem>
          </Tooltip>
        ))}
      </Timeline>
    </div>
    </Paper>
  );
}
