import React, { useState } from "react";
import "../Evaluation/evaluation.css";
import { useContext } from "react";
import { UserContext } from "../../Users/UserContext";
import { useFeedback } from "../FeedbackContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";
import evaluationImage from "../../../Assets/dashboard.png";
import Filter from "../Filter/Filter";
import Spinner from "../../../Common/Spinner";
import Grid from "@mui/material/Grid";
import Empty from "../../../Common/Empty";
import DashboardEvaluationModule from "./DashboardEvaluationModule";
import { useActiveNavigation } from "../../Sidebar/useActiveNavigation";

export default function Dashboard() {
  const queryParams = new URLSearchParams(window.location.search);
  const { filterState } = useFeedback();
  const [target, setTarget] = useState(filterState.target || "Kursteilnehmer");
  const { currentUserData } = useContext(UserContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const { feedbackData, allFeedbackData, dataFetched } = useFeedback();

  useActiveNavigation({
    activePage: "dashboard",
    activeSubPage: "",
    activeModule: "Feedback",
  });

  if (!dataFetched || currentUserData.id === 0) {
    return <Spinner />;
  }

  return (
    <>
      <ToastContainer />
      <Stack
        className="header"
        direction="row"
        spacing={{ xs: 2, sm: 1, md: 2 }}
      >
        <img className="pageIcon" src={evaluationImage} alt="checkmark" />
        <div>
          <h5>Dashboard</h5>
          <p className="siteInfo">
            Erhalte einen umfassenden Überblick über abgegebenes Feedback
          </p>
        </div>
      </Stack>

      <Filter
        target={target}
        setTarget={setTarget}
        setActiveIndex={setActiveIndex}
      />

      {feedbackData.length !== 0 ? (
        <Grid container spacing={2}>
          <DashboardEvaluationModule
            target={target}
            feedbackData={feedbackData}
          />
        </Grid>
      ) : (
        <Empty />
      )}
    </>
  );
}
